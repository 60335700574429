<template>
   <div>
       <Breadcrumb></Breadcrumb>
   </div>
</template>

<script>
export default {
    name:'DataMonitoring',
    data(){
        return{
            
        }
    }

}
</script>

<style lang="less" scoped>

</style>